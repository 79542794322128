/** @jsx jsx */
import {jsx} from "theme-ui"
import Divider from "../elements/divider"
import Inner from "../elements/inner"
import Content from "../elements/content"
import SVG from "./svg"
import {UpDown, UpDownWide} from "../styles/animations"
// @ts-ignore
import ProjectsMDX from "../sections/projects"

// Import images as workaround
// @ts-ignore
import Herohire from "../../../../static/projects/herohire_logo.png";
// @ts-ignore
import Ads from "../../../../static/projects/ads_logo.png";
// @ts-ignore
import Hotcoco from "../../../../static/projects/hotcoco_logo.png";
// @ts-ignore
import GBA from "../../../../static/projects/gba_logo.png";
// @ts-ignore
import GDA from "../../../../static/projects/gda_logo.png";
// @ts-ignore
import GibraltarConfectionery from "../../../../static/projects/gibraltar_confectionery_logo.png";
// @ts-ignore
import LgMaintenance from "../../../../static/projects/lg_maintenance_logo.png";
// @ts-ignore
import Neha from "../../../../static/projects/neha_logo.png";
// @ts-ignore
import RateOutOf10 from "../../../../static/projects/rateoutof10_logo.png";
// @ts-ignore
import Scoom from "../../../../static/projects/scoom_logo.png";
// @ts-ignore
import TheBottleShop from "../../../../static/projects/thebottleshop_logo.png";
// @ts-ignore
import KwFitness from "../../../../static/projects/kwfitness_logo.png";
// @ts-ignore
import BarbaryCoast from "../../../../static/projects/barbary_coast_logo.png";
// @ts-ignore
import SportingFc from "../../../../static/projects/sporting_fc_logo.png";
// @ts-ignore
import Tls from "../../../../static/projects/tls_Logo.png";
// @ts-ignore
import UnlimitedPetsStore from "../../../../static/projects/unlimited_pets_store.png";
// @ts-ignore
import MeltingMomentsGib from "../../../../static/projects/melting_moments_gib.png";
// @ts-ignore
import Brinsop from "../../../../static/projects/brinsop-logo.png";

const ProjectLogo = (
    {
        logo,
        width,
        alt,
        direction,
        link
    }:
        {
            logo: string,
            width: number,
            alt: string,
            direction: string,
            link?: string
        }) => (
    <a
        href={link}
        sx={{
            width: width,
            margin: `auto auto`
        }}
        target={"blank"}
    >
        <img
            src={logo}
            sx={{
                transition: `all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275) !important`,
                "&:hover": {
                    color: `white !important`,
                    transform: direction,
                },
                width: width,
                margin: `auto auto`
            }}
            alt={alt}
        />
    </a>
)

const Projects = ({offset, factor = 2}: { offset: number; factor?: number }) => (
    <div className="projects">
        <Divider
            bg="radial-gradient(circle, rgba(78,194,193,1) 0%, rgba(20,24,33,1) 100%)"
            sx={{clipPath: `polygon(0 15%, 100% 25%, 100% 85%, 0 75%)`}}
            speed={-0.2}
            offset={1.1}
            factor={factor}
        />
        <Content speed={0.4} offset={offset + 0.2} factor={factor}>
            <Inner>
                <div
                    sx={{
                        display: `grid`,
                        gridGap: [4, 4, 4, 5],
                        gridTemplateColumns: `repeat(auto-fit, minmax(250px, 1fr))`,
                        h2: {gridColumn: `-1/1`, color: `white !important`},
                        paddingBottom: 100
                    }}
                >
                    {/*<div
                    sx={{
                        display: `grid`,
                        gridGap: [4, 4, 4, 5],
                        gridTemplateColumns: [`1fr`, `1fr`, `repeat(2, 1fr)`],
                        h2: {gridColumn: `-1/1`, color: `white !important`},
                    }}
                >*/}
                    {/*<ProjectsMDX />*/}
                    <h2>Our Projects</h2>
                    <ProjectLogo logo={Herohire} width={200} alt={"Hero Hire"} direction={"translateY(-10px)"} link={"https://herohire.app"}/>
                    <ProjectLogo logo={Ads} width={200} alt="Advanced Digital Security" direction={"translateY(-10px)"} link={"https://www.ads.gi"}/>
                    <ProjectLogo logo={Hotcoco} width={200} alt="Hot Coco" direction={"translateY(-10px)"}/>
                    <ProjectLogo logo={GBA} width={200} alt="Gibraltar Badminton Association" direction={"translateY(-10px)"}
                                 link={"http://www.badmintongibraltar.com"}/>
                    {/*<ProjectLogo logo={GDA} width={200} alt="Gibraltar Dental Association" direction={"translateY(-10px)"}/>*/}
                    <ProjectLogo logo={GibraltarConfectionery} width={200} alt="Gibraltar Confectionery" direction={"translateY(-10px)"}/>
                    <ProjectLogo logo={Brinsop} width={200} alt="LG Maintenance" direction={"translateY(-10px)"}/>
                    {/*<ProjectLogo logo={LgMaintenance} width={200} alt="LG Maintenance" direction={"translateY(-10px)"}/>*/}
                    {/*<ProjectLogo logo={Neha} width={200} alt="Neha Curry House" direction={"translateY(-10px)"}/>*/}
                    {/*<ProjectLogo logo={RateOutOf10} width={200} alt="Rate out of 10" direction={"translateY(-10px)"}/>*/}
                    {/*<ProjectLogo logo={Scoom} width={200} alt="Scoom" direction={"translateY(-10px)"}/>*/}
                    <ProjectLogo logo={TheBottleShop} width={200} alt="The Bottle Shop" direction={"translateY(-10px)"}
                                 link={"https://www.thebottleshop.gi"}/>
                    <ProjectLogo logo={KwFitness} width={200} alt="KW Fitness" direction={"translateY(-10px)"} link={"https://www.kwfitness.gi"}/>
                    <ProjectLogo logo={BarbaryCoast} width={200} alt="Barbary Coast" direction={"translateY(-10px)"}/>
                    <ProjectLogo logo={SportingFc} width={200} alt="Sporting FC" direction={"translateY(-10px)"}/>
                    <ProjectLogo logo={Tls} width={200} alt="Sporting FC" direction={"translateY(-10px)"}/>
                    <ProjectLogo logo={UnlimitedPetsStore} width={200} alt="Sporting FC" direction={"translateY(-10px)"} link={"https://www.unlimitedpetsstore.com"}/>
                    <ProjectLogo logo={MeltingMomentsGib} width={200} alt="Melting Moments" direction={"translateY(-10px)"} link={"https://www.meltingmomentsgib.com"}/>
                </div>
            </Inner>
        </Content>
        <Divider speed={0.1} offset={offset} factor={factor}>
            <UpDown>
                <SVG icon="star2" width={6} color="icon_brightest" left="85%" top="75%"/>
                <SVG icon="laptop" width={8} color="icon_teal" left="70%" top="20%"/>
                <SVG icon="planet" width={8} stroke color="icon_orange" left="25%" top="5%"/>
                <SVG icon="pencil" hiddenMobile width={24} color="icon_brightest" left="17%" top="60%"/>
            </UpDown>
            <UpDownWide>
                <SVG icon="planet" hiddenMobile width={16} color="icon_green" left="20%" top="90%"/>
                <SVG icon="laptop" width={12} stroke color="icon_brightest" left="90%" top="30%"/>
                <SVG icon="mouse" width={16} color="icon_yellow" left="70%" top="90%"/>
                <SVG icon="planet" hiddenMobile width={16} stroke color="icon_teal" left="18%" top="75%"/>
                <SVG icon="cup" width={6} color="icon_brightest" left="75%" top="10%"/>
                <SVG icon="laptop" hiddenMobile width={8} color="icon_green" left="45%" top="10%"/>
            </UpDownWide>
            <SVG icon="bulb" hiddenMobile width={6} color="icon_brightest" left="4%" top="20%"/>
            <SVG icon="bulb" width={12} color="icon_pink" left="80%" top="60%"/>
            <SVG icon="star2" width={6} color="icon_orange" left="10%" top="10%"/>
            <SVG icon="star2" width={12} color="icon_yellow" left="29%" top="26%"/>
            <SVG icon="pencil" width={16} stroke color="icon_red" left="75%" top="30%"/>
            <SVG icon="laptop" width={8} stroke color="icon_yellow" left="80%" top="70%"/>
        </Divider>
    </div>
)

export default Projects
