/** @jsx jsx */
// @ts-ignore
import React from "react"
import {jsx} from "theme-ui"
import {hidden} from "../styles/utils"
import './../styles/svg-styles.css'

const icons = {
    bulb: {
        shape: (
            <g>
                <path className="bulb1" d="M781.2,1220.6c-42.2,0-84.3,0.1-126.5,0c-17.6,0-27.8-8.1-28.2-21.7c-0.4-14,10.5-23.2,28.5-23.2
                    c85.3-0.1,170.6-0.1,255.9,0c17.2,0,27.5,8.9,27.4,22.6c-0.1,13.8-10.3,22-27.7,22.3c-2.9,0-5.8,0-8.6,0
                    C861.7,1220.6,821.4,1220.6,781.2,1220.6z"/>
                <path className="bulb1" d="M782.7,1111.3c42.6,0,85.3-0.1,127.9,0c17.7,0,27.6,8.1,27.6,22.1c0,13.8-9.9,22.1-27.6,22.1
                    c-85.7,0.1-171.5,0.1-257.2,0c-17.2,0-27.5-9.2-26.9-22.9c0.5-13.4,10.3-21.2,27-21.3C696.5,1111.3,739.6,1111.3,782.7,1111.3z"/>
                <path className="bulb1" d="M782.4,1284.9c-36,0-71.9,0.1-107.9,0c-16.8-0.1-25.4-8-25.2-22.4c0.2-14.4,8.6-21.7,25.9-21.7
                    c71.4-0.1,142.9-0.1,214.3,0c17,0,25.8,7.4,26.1,21.6c0.3,14.7-8.8,22.4-26.7,22.5C853.4,1285,817.9,1284.9,782.4,1284.9z"/>
                <path className="bulb1" d="M782.1,1349.9c-16.3,0-32.6,0.1-48.9,0c-17.6-0.2-27.2-8.4-27-22.8c0.2-13.8,9.5-22.1,26.2-22.1
                    c33.6-0.2,67.2-0.2,100.8,0c16.1,0.1,26,9.2,25.8,22.8c-0.2,13.5-10,21.9-26.4,22.1C815.7,1350.1,798.9,1349.9,782.1,1349.9z"/>
                <g>
                    <path className="bulb1" d="M683.5,560.3c-15.4,1.3-25.3,12.7-25.3,29.1c0,27.9,22.8,52.8,60,63.4c-1.6-23.8-2.4-46.1-4.7-68.4
                        C711.9,568.5,699,559,683.5,560.3z"/>
                    <path className="bulb1" d="M882.6,560.4c-14.9-1.6-28.8,7.2-30.5,21.8c-2.6,23-3.4,46.3-4.9,69.9c35.4-7.1,61.1-35.7,60-64.8
                        C906.5,572.7,896.8,562,882.6,560.4z"/>
                    <path className="bulb1" d="M1182.9,475.8C1145.5,260.7,935.5,115.9,712,153.8C525.5,185.5,391.4,336,377.2,509
                        c-7.8,95.4,8.8,184.5,72.3,260.4c18.4,22.1,36.5,44.6,53.4,67.9c23.7,32.6,42.1,68.7,44.8,109.2c3.5,51.8,25,93.6,59.6,130.5
                        c9.3,9.9,19.2,14.5,32.8,14.3c47.5-0.5,95-0.2,142.5-0.2c48,0,96,0.3,144-0.3c8.1-0.1,18.6-2.1,23.8-7.4
                        c32-32.5,60.2-67.9,63.7-116c3.8-51.9,23-97.6,53.9-138.8c12.7-16.9,25.6-33.6,39.5-49.5C1184.6,690.9,1202.2,586.8,1182.9,475.8z
                         M927.3,612.2c-10.4,33.3-36.4,50.9-67.1,62c-11.2,4.1-15.2,9.3-15.8,21c-4.9,91.9-10.5,183.8-15.9,275.8
                        c-1,17.2-2.2,34.3-3.4,52.3c-7.2,0-13.7,0-23.2,0c6.5-112,12.9-224.1,19.4-336.8c-25.8,0-50,0-77.2,0
                        c6.4,111.4,12.9,223.5,19.4,337c-9.2,0-15.7,0-23.3,0c-2.1-34.1-4.1-67.5-6.1-100.9c-4.6-77.1-9.4-154.1-13.6-231.2
                        c-0.5-9.2-4.4-12.2-12.1-16.2c-18.4-9.7-37.5-19.6-52.8-33.3c-17.9-16-24.3-38.7-20.3-63.1c4.2-25.6,23.9-41.6,50.6-41.8
                        c25.7-0.2,46.7,15.3,50.3,40.2c3.4,23.7,4.4,47.7,5.5,71.6c0.4,8.8,2.7,13.4,12.2,13.3c18.6-0.2,37.2-0.2,55.9,0.2
                        c10.3,0.2,13.7-3.8,14-14.5c0.6-23,2.2-46,4.9-68.8c2.5-20.4,15.1-34.3,34.7-39.9c19.6-5.6,38.4-2.4,53.2,12.7
                        C933.7,569.3,934.1,590.5,927.3,612.2z"/>
                </g>
            </g>
        ),
        viewBox: `0 0 1500 1500`,
    },
    cup: {
        shape: (
            <g id="coffeecup">
                <path className="cup1" d="M1055.4,221.1h-27c-7.2,0-13.1-5.9-13.1-13.1v-27.1c0-7.2,5.9-13.1,13.1-13.1h27c7.2,0,13.1,5.9,13.1,13.1v27
		            C1068.5,215.2,1062.6,221.1,1055.4,221.1z"/>
                <path className="cup1" d="M913.4,233.8h-17.2c-6.2,0-11.4-5.1-11.4-11.4v-17.2c0-6.2,5.1-11.4,11.4-11.4h17.2c6.2,0,11.4,5.1,11.4,11.4
		            v17.2C924.7,228.7,919.6,233.8,913.4,233.8z"/>
                <path className="cup1" d="M1096.5,326.7h-16.7c-6.4,0-11.6-5.2-11.6-11.6v-16.7c0-6.4,5.2-11.6,11.6-11.6h16.7c6.4,0,11.6,5.2,11.6,11.6
		            v16.7C1108.1,321.4,1103,326.7,1096.5,326.7z"/>
                <path className="cup1" d="M1153.1,273.4h-9.7c-4.6,0-8.4-3.7-8.4-8.4v-9.9c0-4.6,3.7-8.4,8.4-8.4h9.7c4.6,0,8.4,3.7,8.4,8.4v9.7
		            C1161.4,269.6,1157.7,273.4,1153.1,273.4z"/>
                <path className="cup1" d="M1007.2,367.5h-76.4c-10.4,0-18.8-8.5-18.8-18.8v-76.4c0-10.4,8.5-18.8,18.8-18.8h76.4
		            c10.4,0,18.8,8.5,18.8,18.8v76.4C1026,359,1017.7,367.5,1007.2,367.5z"/>
                <path className="cup1" d="M1083.7,638.9h-24c-8.1,0-14.6-6.6-14.6-14.6v-24c0-8.1,6.6-14.6,14.6-14.6h24c8.1,0,14.6,6.6,14.6,14.6v24
		            C1098.3,632.3,1091.7,638.9,1083.7,638.9z"/>
                <g>
                    <path className="cup1" d="M1018.2,419.6l-28.7-1.2l0.6-14.8H871.9v-69.8l-464.8-20.5c-13.1-0.6-24.2,9.6-24.8,22.7l-2.5,55.5l-44.4-2
			            c-14.8-0.6-27.3,10.9-28.1,25.7l-5.7,130c-0.6,14.8,10.9,27.3,25.7,28.1l52.2,2.2l2.2,55.5c-20.5,5.5-34.1,17.7-32.8,31.9
			            l43.2,481c0.6,7.1,4.9,13.6,11.6,19.2l3,72.2c1.2,31.4,29.1,57.3,63.3,58.8l369.7,16.3c35.1,1.5,65.1-22.3,68.4-54.5l7-68.1
			            c9.4-5.4,15.5-12.6,16.6-21l64.6-476.3c1.7-13.1-9.2-25.3-27-32.7l5.7-56.3l46.3,2v-34.4h39.9l1.2-29.6h-44.5V419.6H1018.2z
			             M884.3,1253.7c-2,19.5-21.1,33.9-43.4,32.9l-369.7-16.3c-21.8-1-39.6-16.7-40.4-35.8l-2.5-59c7.1,2,15,3.4,23.2,3.7l417.9,18.5
			            c7.2,0.4,14.4-0.1,20.8-1.2L884.3,1253.7z M945.8,652c-2.9-0.4-5.9-0.6-8.9-0.7L411.4,628c-2-0.1-3.9-0.1-5.9-0.1l-2.1-51.3
			            l547.8,24.2L945.8,652z"/>
                </g>
                <path className="cup1" d="M1179.1,513.3h-108.4c-10.7,0-19.3-8.7-19.3-19.3V385.6c0-10.7,8.7-19.3,19.3-19.3h108.4
		            c10.7,0,19.3,8.7,19.3,19.3V494C1198.5,504.6,1189.9,513.3,1179.1,513.3z"/>
                <path className="cup1" d="M1149.7,561.9h-9.7c-4.6,0-8.5-3.7-8.5-8.5v-9.7c0-4.6,3.7-8.5,8.5-8.5h9.7c4.6,0,8.5,3.7,8.5,8.5v9.7
		            C1158.1,558,1154.3,561.9,1149.7,561.9z"/>
            </g>
        ),
        viewBox: `0 0 1500 1500`,
    },
    laptop: {
        shape: (
            <g>
                <path className="laptop0" d="M235.2,271c-17,0-30.8,13.8-30.8,30.8v636.8c0,17,13.8,30.8,30.8,30.8h1035c17,0,30.8-13.8,30.8-30.8V301.8
		            c0-17-13.8-30.8-30.8-30.8H235.2z M1228.5,900.5H274.9V337.8h953.6V900.5z"/>
                <path className="laptop0" d="M1290,982.6H215.5c-7.1,0-14,2.8-19.5,8L87.7,1092.4c-22.4,21-9.5,62.7,19.5,62.7h1291.1
		            c28.9,0,41.9-41.7,19.5-62.7l-108.3-101.8C1304,985.4,1297.1,982.6,1290,982.6z"/>
                <path className="laptop1" d="M1228.5,900.5V337.8H274.9v562.6H1228.5z M742.5,698.2H536.1v-25.7h206.4V698.2z M977.1,839.6H755.5v-19.5
		            h221.6V839.6z M1181,806.2H755.5v-19.4H1181V806.2z M1181,772.8H755.5v-19.4H1181V772.8z M1181,739.4H755.5V720H1181V739.4z
		            M755.5,698.2v-25.7h206.4v25.7H755.5z M1181.3,698.2H974.8v-25.7h206.4V698.2z M316.8,385.6h864.5v263.9H316.8V385.6z
		            M316.8,672.6h206.4v25.7H316.8V672.6z M316.8,721.5h425.5V851H316.8V721.5z"/>
                <rect x="316.8" y="385.6" className="laptop2" width="864.5" height="263.9"/>
                <rect x="316.8" y="672.6" className="laptop2" width="206.4" height="25.7"/>
                <rect x="316.8" y="721.5" className="laptop2" width="425.5" height="129.6"/>
                <rect x="536.1" y="672.6" className="laptop2" width="206.4" height="25.7"/>
                <rect x="755.5" y="672.6" className="laptop2" width="206.4" height="25.7"/>
                <rect x="755.5" y="720" className="laptop2" width="425.5" height="19.5"/>
                <rect x="755.5" y="753.4" className="laptop2" width="425.5" height="19.4"/>
                <rect x="755.5" y="786.8" className="laptop2" width="425.5" height="19.4"/>
                <rect x="755.5" y="820.2" className="laptop2" width="221.6" height="19.5"/>
                <rect x="974.8" y="672.6" className="laptop2" width="206.4" height="25.7"/>
            </g>
        ),
        viewBox: `0 0 1500 1500`,
    },
    mouse: {
        shape: (
            <React.Fragment>
                <path className="mouse1" d="M774.8,1408.5h-61.2c-134,0-243.7-109.6-243.7-243.7V902.1h548.6v262.7
	                C1018.5,1298.8,908.8,1408.5,774.8,1408.5z"/>
                <path className="mouse1" d="M687.5,775.1V609.6 M707.6,471c-130.7,0-236.2,107.1-236.2,237.8v177.7h263.5v-70.6
	                c-30.4-4.1-47.3-27.7-47.3-56V598.8c0-28.4,16.9-51.9,47.3-56V471H707.6z M780.8,471h-22.3v72.2c16.9,5.3,40.5,28.2,40.5,55.5v161.1
	                c0,27.4-23.6,50.3-40.5,55.5v71.1H1022V708.9C1022,578.2,911.5,471,780.8,471z"/>
                <path className="mouse1" d="M782.1,598.8v161.1c0,3.1,0.4,6.2-0.3,9.1c-0.1,0.7,0,1.3-0.1,1.9c-0.2,0.6-0.2,1.3-0.4,1.9
	                c-0.1,0.3-0.1,0.6-0.2,0.9c-0.2,0.6-0.4,1.2-0.7,1.8c-0.1,0.3-0.2,0.6-0.4,0.9c-0.3,0.6-0.5,1.2-0.8,1.8c-0.7,1.4-1.6,2.8-2.5,4.2
	                c-0.7,1.1-1.5,2.1-2.3,3.1c-0.4,0.5-0.8,1-1.3,1.5c-4.9,5.4-11.3,9.5-18.6,11.6c-3.6,1.1-7.4,1.6-11.3,1.6c-2.9,0-4.9-0.3-7.7-0.9
	                c-18.2-3.9-31.1-20.1-31.1-39.5V598.8c0-19.4,12.9-35.6,31.1-39.5c2.7-0.6,5.2-0.9,8.1-0.9c3.9,0,7.5,0.6,11.1,1.6
	                c7.2,2.1,13.5,6.2,18.5,11.6c0.4,0.5,0.8,1,1.2,1.5c0.8,1,1.6,2,2.3,3.1c0.9,1.3,1.7,2.7,2.5,4.2c0.3,0.6,0.6,1.2,0.8,1.8
	                c0.1,0.3,0.3,0.6,0.4,0.9c0.3,0.6,0.5,1.2,0.7,1.8c0.1,0.3,0.2,0.6,0.3,0.9c0.2,0.6,0.4,1.3,0.6,1.9c0.2,0.6-0.4,1.3-0.2,1.9
	                C782.5,592.6,782.1,595.6,782.1,598.8z"/>
                <path className="mouse1" d="M717.8,472c0,0,37.5-76.1,26.3-135.5l-11.9-44.3c-1.8-6.9-4-13.7-6.6-20.3l-16.5-43.5
	                c-1.1-3.5-2.3-6.9-3.7-10.2c-7.8-18.6-9.1-46.9-3.6-69.6c4.3-17.9,15-36.4,31.5-50.2c1.7-1.4,5.7-4.9,7.4-6.4
	                c6.8-6.1,26-21.5,54.4-26l19.9,31c0,0-63,8.9-71.4,66.9c-2.4,16.4,0,33,6.4,48.7c11.9,28.9,39.5,98.7,43.3,133.7
	                c0,0,9.4,58.8-26.9,125.7H717.8z"/>
            </React.Fragment>
        ),
        viewBox: `0 0 1500 1500`,
    },
    pencil: {
        shape: (
            <React.Fragment>
                <path className="pencil1" d="M458,491.1c32.6-32.2,63.7-62.6,95.7-94.2c9.4,8.9,346,344.2,504,502.5c10.9,11,22.3,22.1,30.5,35.1
	                c18.1,28.6,15.2,57.4-8.1,81.6c-23.2,24.2-51.7,27.9-81.3,11.3c-9.7-5.4-18.6-13-26.5-20.9C805.2,839.5,463,497.2,458,491.1z"/>
                <path className="pencil1" d="M600.3,241.2c0,0-196.1,195.7-293.8,293.2c-20.3-23.3-45.8-46.4-63.7-74.3c-32.4-50.7-24.1-112.4,18.9-159.9
	                c28.4-31.3,58.9-60.8,89.3-90.2c67.3-65.1,145.8-68.9,213.6-4.7C577.8,217.8,600.3,241.2,600.3,241.2z"/>
                <path className="pencil1" d="M360.9,586.4c0,0,30.8-33.7,45.8-50.1c13.3,13.1,22.8,23,32.6,32.8c158.1,158,316.1,316,474.1,474.1
	                c9,9,25.3,22.7,28.3,33.6c3.5,12.8,1.2,26.1-8.3,35.6c-6.7,6.7-20.3,10.4-32.8,8.4c-8.3-1.3-20.4-14.6-27.4-21.6
	                c-166.1-166-332-332.1-497.9-498.2C371.3,597.1,360.9,586.4,360.9,586.4z"/>
                <path className="pencil1" d="M597.9,349.4c0,0,30.8-33.7,45.8-50.1c13.3,13.1,22.8,23,32.6,32.8c158.1,158,316.1,316,474.1,474.1
	                c9,9,25.3,22.7,28.3,33.6c3.5,12.8,1.2,26.1-8.3,35.6c-6.7,6.7-20.3,10.4-32.8,8.4c-8.3-1.3-20.4-14.6-27.4-21.6
	                c-166.1-166-332-332.1-497.9-498.2C608.4,360,597.9,349.4,597.9,349.4z"/>
                <path className="pencil1" d="M988.2,1167.9c11.3-45.2,32.3-62.8,75.9-67.9c56.4-6.6,88.4-48.5,101.3-118.2c3.7-19.9,16.8-36.7,35.2-45.1
	                c8.9-4.1,18.4-8.4,28.6-13.1c27,107.7,53.3,212.3,81.4,324.6C1200.2,1220.7,1094.7,1194.4,988.2,1167.9z"/>
            </React.Fragment>
        ),
        viewBox: `0 0 1500 1500`,
    },
    hexa: {
        shape: (
            <polygon
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="27.5,21.904 15,28.809  2.5,21.904 2.5,8.095 15,1.19 27.5,8.095 "
            />
        ),
        viewBox: `0 0 30 30`,
    },
    cross: {
        shape: (
            <path
                strokeWidth="3px"
                d="M60.5,50l34.8-34.8c2.9-2.9,2.9-7.6,0-10.5c-2.9-2.9-7.6-2.9-10.5,0L50,39.5L15.2,4.7c-2.9-2.9-7.6-2.9-10.5,0    c-2.9,2.9-2.9,7.6,0,10.5L39.5,50L4.7,84.8c-2.9,2.9-2.9,7.6,0,10.5c1.4,1.4,3.3,2.2,5.2,2.2s3.8-0.7,5.2-2.2L50,60.5l34.8,34.8    c1.4,1.4,3.3,2.2,5.2,2.2c1.9,0,3.8-0.7,5.2-2.2c2.9-2.9,2.9-7.6,0-10.5L60.5,50z"
            />
        ),
        viewBox: `0 0 100 100`,
    },
    rocket: {
        shape: (
            <path className="st0"
                  fill="#4EC2C1"
                  d="M532.1 647.4L359 695.5c-2.1 0.6-3.9 1.8-5.3 3.4L230.6 847c-1.6 1.9-2.4 4.3-2.4 6.7 0 1 0.1 2.1 0.5 3.1 1 3.4 3.7 6 7.1 7l174.8 50.9c-1.5 37.1 7 69.8 25.5 96.7l-55.4 67.4c-3.5 4.3-3.1 10.5 0.9 14.3l52.6 50c2 1.9 4.8 3 7.6 2.9 2.8-0.1 5.5-1.3 7.4-3.4l61.2-66.7c15.5 5.7 54.3 15.9 113.2 5.1l62.6 171.4c1.2 3.3 4 5.8 7.5 6.6 3.5 0.8 7.1-0.2 9.7-2.6l139.5-132.7c1.6-1.6 2.7-3.5 3.1-5.8l31.3-174.9c38-32.9 76.5-71.2 114.5-113.8 218.5-245.5 251.1-437.4 251.1-530 0-30.5-3.7-47.2-3.8-48 -0.9-4.2-4.3-7.3-8.5-8.1 -10.9-1.9-269.9-41.8-606.9 295.2C623 538.8 579.2 584 532.1 647.4zM720.5 663.3c0-58.6 47.5-106.2 106.2-106.2s106.2 47.5 106.2 106.2 -47.5 106.2-106.2 106.2S720.5 721.9 720.5 663.3z"/>
        ),
        viewBox: `0 0 1500 1500`,
    },
    ufo: {
        shape: (
            <g>
                <rect x="473.5" y="798.7" className="st5" width="26.5" height="242.3"/>
                <circle className="st5" cx="486.8" cy="1041" r="28.9"/>
                <rect x="984.1" y="798.7" className="st5" width="26.5" height="242.3"/>
                <circle className="st5" cx="997.4" cy="1041" r="28.9"/>
                <path className="st5" d="M747.7 798.7l-563.2-91.1c0 0 16.4 182.1 563.2 182.1s563.2-182.1 563.2-182.1L747.7 798.7z"/>
                <ellipse className="st8" cx="747.7" cy="698.2" rx="563.2" ry="133.6"/>
                <ellipse className="st2" cx="747.7" cy="698.2" rx="563.2" ry="133.6"/>
                <path className="st6"
                      d="M998.9 445L998.9 445c-45.7-92.6-141-156.3-251.2-156.3S542.1 352.4 496.4 445l0 0 0 0c-18.4 37.3-28.8 79.3-28.8 123.7 0 41 8.8 79.9 24.6 115 76.7 9.3 163.4 14.5 255.4 14.5s178.7-5.2 255.4-14.5c15.8-35.1 24.6-74 24.6-115C1027.7 524.3 1017.3 482.3 998.9 445z"/>
                <polygon className="st9" points="626.8 695.1 626.8 695.1 626.8 695.1 "/>
                <ellipse className="st6" cx="324.7" cy="673.7" rx="48.1" ry="21.3"/>
                <ellipse className="st6" cx="1143.6" cy="673.7" rx="48.1" ry="21.3"/>
                <ellipse className="st6" cx="486.8" cy="742.7" rx="48.1" ry="21.3"/>
                <ellipse className="st6" cx="997.4" cy="742.7" rx="48.1" ry="21.3"/>
                <ellipse className="st6" cx="747.7" cy="764.1" rx="48.1" ry="21.3"/>
            </g>
        ),
        viewBox: '0 0 1500 1500'
    },
    satellite: {
        shape: (
            <g id="Layer_5">
                <g>
                    <g>
                        <path className="satellite-1" d="M872.8,648.9c5.2,1.8,7.9,7.5,6.1,12.8l-42.5,120.7c-0.2,0.5-0.5,1.4-0.7,1.9l-36.8,104.6
				c-0.2,0.5-0.5,1.4-0.7,1.9l-45.5,128.8c-1.8,5.2-7.5,7.9-12.8,6.1L656,996c-0.5-0.2-1.4-0.5-1.9-0.7L550,958.7
				c-5.2-1.8-7.9-7.5-6.1-12.8l45.4-128.8c0.2-0.5,0.5-1.4,0.7-1.9l36.9-104.6c0.2-0.5,0.5-1.4,0.7-1.9l42.5-120.6
				c1.8-5.2,7.5-7.9,12.8-6.1l86.2,30.4c0.5,0.2,1.4,0.5,1.9,0.7c0,0,4.2,1.5,6.8,2.5c0.6,0.2,1.3,0.5,1.9,0.6
				c0.3,0.1,0.6,0.2,0.8,0.3l1.5,0.5c0.5,0.2,1.4,0.5,1.9,0.7L872.8,648.9z"/>
                    </g>
                    <polygon className="satellite-2" points="627.2,709.6 589.6,816.2 524.2,793 561.7,686.6 		"/>
                    <g>
                        <path className="satellite-1" d="M564.5,648.4c5.2,1.8,7.9,7.5,6.1,12.8l-8.6,24.5c-0.2,0.5-0.5,1.4-0.7,1.9l-36.8,104.6
				c-0.2,0.5-0.5,1.4-0.7,1.9l-8.6,24.5c-1.8,5.2-7.5,7.9-12.8,6.1L120,689.8c-5.2-1.8-7.9-7.5-6.1-12.8l55.4-157.3
				c1.8-5.2,7.5-7.9,12.8-6.1L564.5,648.4z"/>
                    </g>

                    <rect x="793.6" y="813.3" transform="matrix(0.3323 -0.9432 0.9432 0.3323 -232.2325 1368.0193)" className="satellite-2"
                          width="112.9" height="69.5"/>
                    <g>
                        <path className="satellite-1" d="M1243.6,1085.7L861.2,950.9c-5.2-1.8-7.9-7.5-6.1-12.8L910.5,781c1.8-5.2,7.5-7.9,12.8-6.1l382.4,134.8
				c5.2,1.8,7.9,7.5,6.1,12.8l-55.4,157.2C1254.5,1084.8,1248.8,1087.5,1243.6,1085.7z"/>
                    </g>
                    <path className="satellite-2" d="M998.8,556.4c-23.3,66.2-119.3,92.8-215.9,60.8c-0.9-0.3-1.9-0.6-2.8-0.9c-0.8-0.2-1.6-0.5-2.4-0.8
			c-2.6-0.9-5.2-1.9-7.7-2.8c-93.8-36.3-150.5-116.3-127.4-181.8l161.8,57l19.8,7h0.1l12.7,4.4L998.8,556.4z"/>
                    <polygon className="satellite-1" points="881.6,352.3 872,384 837,499.3 837,499.4 824.3,494.9 824.2,494.9 804.4,487.9 848.3,375.5
			849.2,373.3 860.4,344.7 		"/>
                    <path className="satellite-2" d="M903.9,362.2c-6,17-24.6,25.9-41.5,19.9c-17-6-25.9-24.6-19.9-41.5c6-17,24.6-25.9,41.5-19.9
			C901,326.6,909.9,345.2,903.9,362.2z"/>
                </g>
            </g>
        ),
        viewBox: '0 0 1500 1500'
    },
    star1: {
        shape: (
            <g id="Layer_6">
                <polygon className="star-1" points="1262.7,750 1016.5,903.8 1006.5,1194 749.9,1057.7 493.6,1193.9 483.3,903.8 237.3,750 483.3,596.3
		493.6,306 749.9,442.4 1006.5,306.1 1016.5,596.3 	"/>
            </g>
        ),
        viewBox: '0 0 1500 1500'
    },
    star2: {
        shape: (
            <path className="star2" d="M1184.4,583.9l-288.8-42c-3.3-0.5-6.1-2.5-7.5-5.5L759,274.8c-3.7-7.4-14.3-7.4-17.9,0L611.9,536.4
						c-1.5,3-4.3,5-7.5,5.5l-288.8,42c-8.2,1.2-11.5,11.3-5.5,17.1l209,203.7c2.4,2.3,3.4,5.6,2.9,8.9L472.6,1101
						c-1.4,8.2,7.2,14.4,14.5,10.5l258.3-135.8c2.9-1.5,6.4-1.5,9.3,0l258.3,135.8c7.3,3.9,15.9-2.4,14.5-10.5l-49.3-287.6
						c-0.6-3.2,0.5-6.6,2.9-8.9l209-203.7C1195.9,595.1,1192.6,585.1,1184.4,583.9z"/>
        ),
        viewBox: ' 0 0 1500 1500'
    },
    planet: {
        shape: (
            <g id="Saturn">
                <rect id="Background_18_" x="-19.9" y="-19.9" className="planet1" width="1539.7" height="1539.7"/>
                <polygon className="planet2"
                         points="1519.9,644.2 1334,458.7 1031.2,516.9 283.9,913.9 166,1041.3 645.4,1519.9 1519.9,1519.9"/>
                <g id="Saturn_2_">
                    <g id="Saturn_1_">

                        <ellipse transform="matrix(0.995 -9.967332e-02 9.967332e-02 0.995 -71.0187 78.4883)" className="planet3" cx="750" cy="750"
                                 rx="378.8" ry="378.8"/>
                        <g>
                            <path className="planet4" d="M606.5,729.6c0,0,220.2-14.1,424.7-233.5c-29.6-32.7-64.7-60.1-103.8-80.9
						C884.3,504.2,793.6,616.1,606.5,729.6z"/>
                            <path className="planet4" d="M1126.2,795.1c-182.3,170.3-390.8,259.4-525.8,303.1c11.6,5,23.6,9.5,35.7,13.3
						c83.3-19.5,225.3-64.5,329-159.9c0,0-44.2,105-207.1,177.2c9.8-0.2,19.7-0.8,29.6-1.8c28.7-2.9,56.4-8.9,82.6-17.7
						c33.8-19.7,66.7-42.9,96.5-70.2c0,0-6,14.2-21.6,35.7C1044.1,1015.2,1112.3,912.3,1126.2,795.1z"/>
                            <path className="planet4" d="M850,727.4c-93,92.6-284.5,169-425.8,216.1c8.3,13.9,17.4,27.2,27.3,39.9C747.3,872.2,850,727.4,850,727.4
						z"/>
                            <path className="planet3" d="M1106.6,622c-104,119.4-322.4,329.5-620.7,399.7c22.1,21.5,46.7,40.2,73.4,55.7
						c13.2,7.7,26.9,14.7,41.1,20.7c135-43.6,343.4-132.8,525.8-303.1c1.7-14.1,2.6-28.3,2.6-42.8c0.1-13.2-0.5-26.6-1.9-40
						C1123.8,680.7,1116.8,650.5,1106.6,622z"/>
                            <path className="planet3" d="M965.2,951.6c-103.7,95.3-245.7,140.4-329,159.9c38.6,12.2,79.7,18.3,122,17.4
						C921,1056.5,965.2,951.6,965.2,951.6z"/>
                            <path className="planet3" d="M870.4,1109.3c26.5-8.8,51.5-20.5,74.9-34.6c15.6-21.5,21.6-35.7,21.6-35.7
						C937.1,1066.4,904.2,1089.6,870.4,1109.3z"/>
                            <path className="planet4"
                                  d="M965.3,611c0,0,56.2-16.7,108.6-57.7c-5.9-9.7-12.2-19.1-18.9-28.1C1029.6,554.6,998.9,585.7,965.3,611z"
                            />
                        </g>
                        <g>
                            <path className="planet5" d="M1334,458.7c-18.6-37.2-136.9-19.2-301.6,38.7c9.4,10.5,18.3,21.6,26.6,33.2
						c71.5-18.1,119.5-19.2,129.5,0.7c9.9,19.9-19.9,57.6-77.3,103.8C1039.4,692.9,924.8,764,790.4,831S530.2,946.8,441,969.3
						c-71.5,18.1-119.5,19.2-129.5-0.7c-9.9-19.9,19.9-57.6,77.3-103.8c-4.2-13.4-7.8-27.1-10.5-41.2
						C233,920.4,147.5,1004,166,1041.3c18.6,37.2,136.9,19.2,301.6-38.7c99.1-34.9,215.1-84.2,336.2-144.6
						c121.2-60.4,230.3-123.4,317.8-181.6C1267,579.6,1352.5,495.9,1334,458.7z"/>
                        </g>
                    </g>
                    <g id="Star_16_" className="planet6">
                        <g className="planet7">
                            <path className="planet8" d="M417.9,325.7c-23.7,0-42.8-19.2-42.8-42.8c0,23.7-19.2,42.8-42.8,42.8c23.7,0,42.8,19.2,42.8,42.8
						C375,344.9,394.2,325.7,417.9,325.7z"/>
                        </g>
                        <g className="planet7">
                            <path className="planet8" d="M689.9,1286c-23.7,0-42.8-19.2-42.8-42.8c0,23.7-19.2,42.8-42.8,42.8c23.7,0,42.8,19.2,42.8,42.8
						C647.1,1305.2,666.2,1286,689.9,1286z"/>
                        </g>
                        <g className="planet7">
                            <path className="planet8" d="M1310.9,976.6c-23.7,0-42.8-19.2-42.8-42.8c0,23.7-19.2,42.8-42.8,42.8c23.7,0,42.8,19.2,42.8,42.8
						C1268.1,995.7,1287.3,976.6,1310.9,976.6z"/>
                        </g>
                    </g>
                </g>
            </g>
        ),
        viewBox: '0 0 1500 1500'
    }
}

type IconType =
    "bulb"
    | "cup"
    | "laptop"
    | "mouse"
    | "pencil"
    | "hexa"
    | "cross"
    | "rocket"
    | "ufo"
    | "satellite"
    | "star1"
    | "star2"
    | "planet"

type SVGProps = {
    stroke?: boolean
    color?: string | number | any
    width: number
    icon: IconType
    left: string
    top: string
    hiddenMobile?: boolean
}

const defaultProps = {
    stroke: false,
    hiddenMobile: false,
}

const SVG = ({stroke, color, width, icon, left, top, hiddenMobile}: SVGProps) => (
    <svg
        // @ts-ignore
        sx={{
            position: `absolute`,
            stroke: stroke ? `currentColor` : `none`,
            fill: stroke ? `none` : `currentColor`,
            display: hiddenMobile ? hidden : `block`,
            color,
            width,
            left,
            top,
        }}
        viewBox={icons[icon].viewBox}
    >
        {icons[icon].shape}
    </svg>
)

export default SVG

SVG.defaultProps = defaultProps
