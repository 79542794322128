import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Koffee from '../../../../static/koffee_cup.png';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`About Us`}</h2>
    <blockquote>
      <p parentName="blockquote">{`Every great idea, starts with a cup of Koffee `}<img src={Koffee} width={25} alt="Koffee cup" /></p>
    </blockquote>
    <p>{`As a young and ambitious design studio, we strongly believe in creating high quality, bespoke products that are original and perfectly match the
ideas of our client.`}</p>
    <p>{`We may be a small group, but we understand that having websites, apps and graphics created can be expensive. This is why we believe you should not
have to break the bank for professional bespoke products.`}</p>
    <p>{`Have an idea you'd like to share with us? `}<a parentName="p" {...{
        "href": "mailto:hello@koffeestudios.com"
      }}>{`Let us know.`}</a>{` We'd love to work with you.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      